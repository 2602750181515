export default {
  tableColumnProps: [
    {
      className: null,
      label: 'page.event.trigger.name',
      prop: 'name',
      width: '100px',
      minWidth: '40px',
    },

    {
      className: 'monitor',
      label: 'page.event.trigger.message',
      prop: 'monitor',
      width: '160px',
      minWidth: '105px',
    },
    {
      className: 'mode',
      label: 'page.event.trigger.type',
      prop: 'mode',
      minWidth: '145px',
    },
  ],
  toggleField: 'enabled',
};
