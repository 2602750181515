export default {
  tableColumnProps: [
    {
      className: null,
      label: 'page.event.trigger.name',
      prop: 'name',
      width: '100px',
      minWidth: '40px',
    },
    {
      className: 'type',
      label: 'page.event.trigger.type',
      prop: 'type',
      width: '160px',
      minWidth: '105px',
    },
    {
      className: null,
      label: 'page.event.trigger.message',
      prop: 'message',
      width: null,
      minWidth: '145px',
    },
  ],
  toggleField: 'enabled',
};
