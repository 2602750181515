export default {
  tableColumnProps: [
    {
      label: 'page.event.type',
      type: null,
      columnKey: null,
      prop: 'type',
      width: '160px',
      minWidth: null,
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.event.time',
      type: null,
      columnKey: null,
      prop: 'create_time',
      width: '170px',
      minWidth: null,
      sortable: 'custom',
      resizable: null,
      searchable: false,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },

    {
      label: 'page.event.description',
      type: null,
      columnKey: null,
      prop: 'description',
      width: null,
      minWidth: '170px',
      sortable: false,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
  ],
};