import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1/groups`;

export default {
  getAll(groupId, filter) {
    return axios
      .get(`${apiUrl}/${groupId}/forwards`, { params: filter })
      .then((response) => {
        return response.data;
      });
  },
  get(groupId, id) {
    return axios
      .get(`${apiUrl}/${groupId}/forwards/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create(groupId, forward) {
    return axios
      .post(`${apiUrl}/${groupId}/forwards`, forward)
      .then((response) => {
        return response.data;
      });
  },
  update(groupId, forwardId, forward) {
    return axios
      .put(`${apiUrl}/${groupId}/forwards/${forwardId}`, forward)
      .then((response) => {
        return response.data;
      });
  },
  delete(groupId, id) {
    return axios
      .delete(`${apiUrl}/${groupId}/forwards/${id}`)
      .then((response) => {
        return response.data;
      });
  },
};
