var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-app location"},[_c('section',{staticClass:"app-main-toolbar"},[_c('el-row',{staticClass:"no-margin"},[_c('h1',[_vm._v(_vm._s(_vm.$t('page.event.title.eventList')))])]),_c('el-row',{attrs:{"type":"flex","justify":"end"}})],1),_c('section',{staticClass:"app-main-content"},[_c('data-table',{attrs:{"data":_vm.source.items,"current-page":_vm.filter.page,"page-size":20,"total":_vm.source.paging.total,"loading":_vm.page.loading,"config":_vm.page.tableProps,"show-tool-bar":false,"layout":"table, pagination","table-props":{ border: false,
                      height: "calc(100% - 74px)",
                      "row-class-name": "coretronic",
                      "header-row-class-name": "coretronic table-header" },"pagination-props":{ background: true,
                           small: true,
                           layout: 'prev, pager, next',
                           pageSizes: [20],
                           disabled: _vm.page.loading}},on:{"query-change":_vm.loadData}},[_vm._l((_vm.page.tableProps.tableColumnProps),function(columnProp){return _c('el-table-column',{key:columnProp.prop,attrs:{"type":columnProp.type,"label":_vm.$t(columnProp.label),"column-key":columnProp.columnKey,"prop":columnProp.prop,"width":columnProp.width,"min-width":columnProp.minWidth,"sortable":columnProp.sortable,"resizable":columnProp.resizable,"formatter":columnProp.formatter,"align":columnProp.align,"header-align":columnProp.headerAlign,"class-name":columnProp.className,"label-class-name":columnProp.labelClassName},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(columnProp.prop == 'description')?_c('div',[_vm._v(" "+_vm._s(scope.row.error || scope.row.result)+" ")]):(columnProp.prop == 'type')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('page.event.typeType.' + scope.row.type))+" ")]):(columnProp.prop == 'create_time')?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(scope.row.create_time,_vm.$t('formatter.dateTime')))+" ")]):_c('div',[_vm._v(" "+_vm._s(scope.row[columnProp.prop])+" ")])]}}],null,true)})}),_c('el-table-column',{attrs:{"label":"","fixed":"right","width":"55px"}})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }