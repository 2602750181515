<template>
  <div class="sub-app trigger">
    <section class="app-main-toolbar">
      <el-row class="no-margin trigger-title title-section">
        <h1>{{ $t('page.event.title.triggers') }}</h1>
      </el-row>
      <el-row
        type="flex"
        justify="end"
      />
    </section>
    <section class="app-main-content">
      <column-data-table
        class="trigger-data-table"
        :config="page.tableProps"
        :formatter="formatForward"
        :data="source.items"
        @toggle="toggleItem"
      >
        <template slot="toolbar">
          <el-row
            class="trigger-toolbar"
            type="flex"
            justify="end"
          >
            <el-button
              :disabled="!isAdmin"
              type="primary"
              class="main-actions"
              @click="dialogOpen()"
            >
              <fa-icon icon="plus" /> {{ $t('button.add') }}
            </el-button>
          </el-row>
        </template>
        <template
          slot="actions"
          scope="scope"
        >
          <fa-button
            :no-border="true"
            size="mini"
            icon=""
            class="action-button edit"
            @click="dialogOpen(scope.row.id)"
          />
          <copy-url
            :url="scope.row.url"
            class="action-button popover-button"
          >
            <fa-button
              :no-border="true"
              :disable-escaped="true"
              size="mini"
              icon=""
              class="action-button url"
            />
          </copy-url>
        </template>
      </column-data-table>
    </section>
    <el-row
      class="no-margin forwards-title title-section"
      style="text-align:center"
    >
      <h1>{{ $t('page.event.title.forwards') }}</h1>
    </el-row>
    <el-row
      type="flex"
      justify="end"
    >
      <column-data-table
        :config="page.forwardTableProps"
        :formatter="formatForward"
        :data="source.forwardItems"
        style="height:450px"
        @toggle="toggleForwardItem"
      >
        <template slot="toolbar">
          <el-row
            class="trigger-toolbar"
            type="flex"
            justify="end"
          >
            <el-button
              :disabled="!isAdmin"
              type="primary"
              class="main-actions"
              @click="forwardDialogOpen()"
            >
              <fa-icon icon="plus" /> {{ $t('button.add') }}
            </el-button>
          </el-row>
        </template>
        <template
          slot="actions"
          scope="scope"
        >
          <fa-button
            :no-border="true"
            size="mini"
            icon=""
            class="action-button edit"
            @click="forwardDialogOpen(scope.row.id)"
          />
          <el-popover
            :title="$t('button.url')"
            :content="scope.row.url"
            placement="top"
            trigger="click"
            width="427"
            class="action-button popover-button"
            popper-class="trigger-url-popover"
          >
            <div class="popover-url">
              <div class="popover-text">
                <textarea
                  :id="`event-url-${scope.rIndex}`"
                  :value="scope.row.url"
                  class="hidden-text-area"
                  rows="3"
                  readonly
                />
              </div>
              <div class="popover-copy">
                <el-button
                  type="primary"
                  size="mini"
                  @click="copyToClip(scope.rIndex)"
                >
                  {{ $t('button.copy') }}
                </el-button>
              </div>
            </div>
            <!-- <el-button slot="reference">hover 激活</el-button> -->
            <fa-button
              slot="reference"
              :no-border="true"
              :disable-escaped="true"
              size="mini"
              icon=""
              class="action-button url"
            />
          </el-popover>
        </template>
      </column-data-table>
    </el-row>

    <default-dialog
      :show-dialog="triggerShow"
      :title="(triggerMode == 'add') ? $t('page.event.triggerEditDialog.addTitle') : $t('page.event.triggerEditDialog.updateTitle')"
      :next-button-text="(triggerMode == 'add') ? $t('button.create') : $t('button.save')"
      :footer-align="(triggerMode == 'update') ? 'right' : 'center'"
      @close="dialogClose"
      @next="dialogSave"
    >
      <template slot="main">
        <el-form
          ref="form"
          :model="trigger"
          label-width="120px"
        >
          <el-form-item :label="$t('page.event.triggerEditDialog.name')">
            <el-input
              v-model="trigger.name"
              class="main-input"
            />
          </el-form-item>
          <el-form-item :label="$t('page.event.triggerEditDialog.type')">
            <template>
              <el-radio
                v-model="trigger.type"
                class="coretronic"
                label="alert"
              >
                {{ $t('page.event.triggerEditDialog.types.alert') }}
              </el-radio>
              <!-- <el-radio v-model="trigger.type" class="coretronic" label="curise">{{ $t('page.event.triggerEditDialog.types.curise') }}</el-radio> -->
              <el-radio
                v-model="trigger.type"
                class="coretronic"
                label="mission"
              >
                {{ $t('page.event.triggerEditDialog.types.mission') }}
              </el-radio>
              <!-- <el-radio v-model="trigger.type" class="coretronic" label="goto">{{ $t('page.event.triggerEditDialog.types.curise') }}</el-radio> -->
            </template>
          </el-form-item>
          <el-form-item :label="$t('page.event.triggerEditDialog.enabled')">
            <el-switch
              v-model="trigger.enabled"
              class="coretronic"
            />
          </el-form-item>
          <el-form-item :label="$t('page.event.triggerEditDialog.auto')">
            <el-switch
              v-model="trigger.auto"
              class="coretronic"
              disabled
            />
          </el-form-item>
          <el-form-item :label="$t('page.event.triggerEditDialog.message')">
            <el-input
              v-model="trigger.message"
              type="textarea"
              class="main-input"
            />
          </el-form-item>
          <el-form-item :label="$t('page.event.triggerEditDialog.ip')">
            <el-input
              v-model="trigger.address"
              class="main-input"
            />
          </el-form-item>
          <el-form-item
            v-if="trigger.type == 'mission'"
            :label="$t('page.event.triggerEditDialog.mission')"
          >
            <template v-if="trigger.mission == null">
              <el-button
                :disabled="!isAdmin"
                type="success"
                class="load-mission"
                @click="assign()"
              >
                <img src="/asserts/icon/trigger-loadmission-icon.svg"><span class="text">{{ $t('page.event.triggerEditDialog.load') }}</span>
              </el-button>
            </template>
            <template v-else>
              <div class="mission-area">
                <div
                  :title="trigger.mission.name"
                  class="mission-info"
                >
                  <data-table-thumbnail :value="trigger.mission" />
                  <label class="overflow-cut">{{ trigger.mission.name }}</label>
                </div>
                <div class="reassign">
                  <button
                    class="image-button reassign"
                    @click="assign"
                  />
                </div>
              </div>
            </template>
          </el-form-item>
        </el-form>
      </template>
      <template slot="footer">
        <el-button
          v-if="(triggerMode == 'update')"
          :disabled="!isAdmin"
          class="main-actions right-button"
          type="warning"
          @click="deleteShow = true"
        >
          {{ $t('button.delete') }}
        </el-button>
      </template>
    </default-dialog>
    <assign-mission-dialog
      ref="assignMission"
      :require-drone="false"
    />
    <default-dialog
      :show-dialog="deleteShow"
      :center="true"
      :body-center="true"
      :next-button-text="$t('page.event.triggerDeleteDialog.confirm')"
      @close="deleteShow = false"
      @next="deleteAction"
    >
      <template slot="main">
        <h2>{{ $t('page.event.triggerDeleteDialog.title') }}</h2>
        <p>{{ $t('page.event.triggerDeleteDialog.body') }}</p>
      </template>
    </default-dialog>

    <default-dialog
      :show-dialog="forwardShow"
      :title="(forwardMode == 'add') ? $t('page.event.forwardEditDialog.addTitle') : $t('page.event.forwardEditDialog.updateTitle')"
      :next-button-text="(forwardMode == 'add') ? $t('button.create') : $t('button.save')"
      :footer-align="(forwardMode == 'update') ? 'right' : 'center'"
      @close="forwardDialogClose"
      @next="forwardDialogSave"
    >
      <template slot="main">
        <el-form
          ref="form"
          :model="forward"
          label-width="120px"
        >
          <el-form-item :label="$t('page.event.forwardEditDialog.name')">
            <el-input
              v-model="forward.name"
              class="main-input"
            />
          </el-form-item>
          <el-form-item :label="$t('page.event.forwardEditDialog.mode')">
            <template>
              <el-radio
                v-model="forward.mode"
                class="coretronic"
                label="restful"
              >
                {{ $t('page.event.forwardEditDialog.types.http') }}
              </el-radio>
              <!-- <el-radio v-model="trigger.type" class="coretronic" label="curise">{{ $t('page.event.triggerEditDialog.types.curise') }}</el-radio> -->
              <el-radio
                v-model="forward.mode"
                class="coretronic"
                label="socket"
              >
                {{ $t('page.event.forwardEditDialog.types.websocket') }}
              </el-radio>
              <!-- <el-radio v-model="trigger.type" class="coretronic" label="goto">{{ $t('page.event.triggerEditDialog.types.curise') }}</el-radio> -->
            </template>
          </el-form-item>
          <el-form-item :label="$t('page.event.forwardEditDialog.forwardType')">
            <template>
              <el-radio
                v-model="monitorString"
                class="coretronic"
                label="1"
              >
                {{ $t('page.event.forwardEditDialog.types.event') }}
              </el-radio>
              <!-- <el-radio v-model="trigger.type" class="coretronic" label="curise">{{ $t('page.event.triggerEditDialog.types.curise') }}</el-radio> -->
              <el-radio
                v-model="monitorString"
                class="coretronic"
                label="2"
              >
                {{ $t('page.event.forwardEditDialog.types.status') }}
              </el-radio>
              <!-- <el-radio v-model="trigger.type" class="coretronic" label="goto">{{ $t('page.event.triggerEditDialog.types.curise') }}</el-radio> -->
            </template>
          </el-form-item>
          <el-form-item :label="$t('page.event.forwardEditDialog.enabled')">
            <el-switch
              v-model="forward.enabled"
              class="coretronic"
            />
          </el-form-item>

          <el-form-item :label="$t('page.event.forwardEditDialog.url')">
            <el-input
              v-model="forward.url"
              class="main-input"
            />
          </el-form-item>

          <el-form-item :label="$t('page.event.forwardEditDialog.account')">
            <el-input
              v-model="forward.account"
              class="main-input"
            />
          </el-form-item>
          <el-form-item :label="$t('page.event.forwardEditDialog.password')">
            <el-input
              v-model="forward.password"
              type="password"
              class="main-input"
            />
          </el-form-item>
        </el-form>
      </template>
      <template slot="footer">
        <el-button
          v-if="(forwardMode == 'update')"
          :disabled="!isAdmin"
          class="main-actions right-button"
          type="warning"
          @click="forwardDeleteShow = true"
        >
          {{ $t('button.delete') }}
        </el-button>
      </template>
    </default-dialog>
    <default-dialog
      :show-dialog="forwardDeleteShow"
      :center="true"
      :body-center="true"
      :next-button-text="$t('page.event.forwardDeleteDialog.confirm')"
      @close="forwardDeleteShow = false"
      @next="deleteForwardAction"
    >
      <template slot="main">
        <h2>{{ $t('page.event.forwardDeleteDialog.title') }}</h2>
        <p>{{ $t('page.event.forwardDeleteDialog.body') }}</p>
      </template>
    </default-dialog>
  </div>
</template>

<script>
import { CopyObject } from '@/utils/common.js';
import { mapActions, mapGetters } from 'vuex';
import { logger } from '@/logger/index';
import tableProps from '@/config/tableProps/triggerList.js';
import forwardTableProps from '@/config/tableProps/forwardList.js';
import ColumnDataTable from '@/components/DataTable/ColumnDataTable';
import FaButton from '@/components/FaButton';
import TriggerApi from '@/services/api/domain/group/trigger';
import ForwardApi from '@/services/api/domain/group/forward';
import DefaultDialog from '@/components/DefaultDialog.vue';
import AssignMissionDialog from '@/components/AssignMissionDialog.vue';
import DataTableThumbnail from '@/components/DataTable/Thumbnail.vue';
import CopyUrl from '@/components/CopyUrl.vue';

export default {
  name: 'TriggerSubApp',
  components: {
    AssignMissionDialog,
    ColumnDataTable,
    FaButton,
    DefaultDialog,
    DataTableThumbnail,
    CopyUrl,
  },
  data() {
    return {
      deleteShow: false,
      forwardDeleteShow: false,
      trigger: CopyObject(this.$store.state.trigger.trigger),
      forward: CopyObject(this.$store.state.forward.forward),
      source: {
        items: [],
        paging: {
          total: 0,
        },
        forwardItems: [],
      },
      filter: {
        page: 1,
      },
      page: {
        loading: false,
        selectedItems: [],
        tableProps,
        forwardTableProps,
      },
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      groupId: 'user/groupId',
      triggerMode: 'trigger/getMode',
      triggerData: 'trigger/getTrigger',
      triggerShow: 'trigger/shouldShow',
      forwardMode: 'forward/getMode',
      forwardData: 'forward/getForward',
      forwardShow: 'forward/shouldShow',
      isAdmin: 'user/isAdmin',
    }),
    monitorString: {
      get() {
        logger.debug(`${this.forward.monitor}`);
        return `${this.forward.monitor}`;
      },
      set(value) {
        logger.debug(parseInt(value));
        this.forward.monitor = parseInt(value);
      },
    },
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    formatForward(source) {
      logger.debug('formatForward');
      logger.debug(source);
      if (source == 'restful') { return 'HTTP Post'; }
      if (source == 'socket') { return 'WebSocket'; }
      if (source == '1') { return this.$t('page.event.droneEvent'); }
      if (source == '2') { return this.$t('page.event.droneStatus'); }
      if (source == 'mission') { return this.$t('page.event.triggerEditDialog.types.mission'); }
      if (source == 'alert') { return this.$t('page.event.triggerEditDialog.types.alert'); }
      return source;
    },
    async loadData() {
      try {
        this.page.loading = true;
        const { meta, data } = await TriggerApi.getAll(this.groupId);
        this.source.items = data.triggers;
        this.source.paging.total = data.total;
      } catch (error) {
        this.$showFail(error);
      }
      try {
        this.page.loading = true;
        const { meta, data } = await ForwardApi.getAll(this.groupId);
        this.source.forwardItems = data.forwards;
        // this.source.paging.total = data.total;
        logger.debug('loadData');
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
    },
    async dialogOpen(triggerId) {
      if (triggerId) {
        try {
          this.page.loading = true;
          const { data } = await TriggerApi.get(this.groupId, triggerId);
          this.setTrigger(data);
          this.trigger = CopyObject(this.$store.state.trigger.trigger);
          this.setTriggerMode('update');
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.setTriggerMode('add');
      }
    },
    async forwardDialogOpen(forwardId) {
      if (forwardId) {
        try {
          this.page.loading = true;
          const { data } = await ForwardApi.get(this.groupId, forwardId);
          this.setForward(data);
          this.forward = CopyObject(this.$store.state.forward.forward);
          this.setForwardMode('update');
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.setForwardMode('add');
      }
      logger.debug('forwardDialogOpen');
    },
    async toggleItem(triggerId, triggerIndex) {
      this.page.loading = true;
      try {
        const { data } = await TriggerApi.get(this.groupId, triggerId);
        data.enabled = !this.source.items[triggerIndex].enabled;
        await TriggerApi.update(this.groupId, data.id, data);
        this.page.loading = false;
      } catch (error) {
        this.$showFail(error);
      }
      this.loadData();
    },
    async toggleForwardItem(forwardId, forwardIndex) {
      this.page.loading = true;
      try {
        const { data } = await ForwardApi.get(this.groupId, forwardId);
        data.enabled = !this.source.forwardItems[forwardIndex].enabled;
        await ForwardApi.update(this.groupId, data.id, data);
        this.page.loading = false;
      } catch (error) {
        this.$showFail(error);
      }
      this.loadData();
    },
    dialogClose() {
      this.setTriggerMode(null);
      this.trigger = CopyObject(this.$store.state.trigger.trigger);
    },

    forwardDialogClose() {
      this.setForwardMode(null);
      this.forward = CopyObject(this.$store.state.forward.forward);
    },
    async dialogSave() {
      this.setTrigger(this.trigger);
      let apiData;
      try {
        apiData = await this.exportTrigger();
      } catch (error) {
        this.$showFail('Necessary field not yet filled in');
        return;
      }
      const loading = this.$loading({
        lock: true,
      });
      try {
        if (this.triggerMode === 'add') {
          const status = await TriggerApi.create(this.group.id, apiData);
        } else {
          const status = await TriggerApi.update(this.group.id, apiData.id, apiData);
        }
        this.$showSuccess(status.msg);
        this.dialogClose();
      } catch (error) {
        this.$showFail(error);
      }
      await this.loadData();
      loading.close();
    },
    async forwardDialogSave() {
      this.setForward(this.forward);
      let apiData;
      try {
        apiData = await this.exportForward();
      } catch (error) {
        this.$showFail(error.message);
        return;
      }
      const loading = this.$loading({
        lock: true,
      });
      try {
        if (this.forwardMode === 'add') {
          const status = await ForwardApi.create(this.group.id, apiData);
        } else {
          const status = await ForwardApi.update(this.group.id, apiData.id, apiData);
        }
        this.$showSuccess(status.msg);
        this.forwardDialogClose();
      } catch (error) {
        this.$showFail(error);
      }
      await this.loadData();
      loading.close();
    },
    assign(event) {
      if (event) {
        event.preventDefault();
      }
      this.setTrigger(this.trigger);
      this.$refs.assignMission.open();
    },
    async deleteAction() {
      try {
        this.deleteShow = false;
        this.setTriggerMode(null);
        this.page.loading = true;
        const status = await TriggerApi.delete(this.groupId, this.trigger.id);
        this.$showSuccess(status.msg);
        this.trigger = CopyObject(this.$store.state.trigger.trigger);
      } catch (error) {
        this.$showFail(error);
      }

      this.loadData();
    },
    async deleteForwardAction() {
      try {
        this.forwardDeleteShow = false;
        this.setForwardMode(null);
        this.page.loading = true;

        const status = await ForwardApi.delete(this.groupId, this.forward.id);
        this.$showSuccess(status.msg);
        this.trigger = CopyObject(this.$store.state.forward.forward);
      } catch (error) {
        this.$showFail(error);
      }

      this.loadData();
    },
    ...mapActions({
      setTriggerMode: 'trigger/setMode',
      setTrigger: 'trigger/setTrigger',
      importTrigger: 'trigger/importTrigger',
      exportTrigger: 'trigger/exportTrigger',
      exportForward: 'forward/exportForward',
      setForwardMode: 'forward/setMode',
      setForward: 'forward/setForward',
    }),

  },
};
</script>

<style lang="scss">
.sub-app.trigger {
  .load-mission {
    height: 30px;
    padding: 3px 10px 3px 10px;
    > span {
      display: table-cell;

      * {
        vertical-align: middle;
      }
      img {
        margin-right: 5px;
      }
    }
  }

  span.popover-button {
    display: flex;
  }

  .mission-area {
    display: flex;
    flex-direction: row;
    .mission-info {
      display: flex;
      flex-direction: column;
      margin-right: 25px;
      img {
        width: 156px;
        height: 104.4px;
      }
      label:not(.infomation__label) {
        width: 156px;
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
    }

    .reassign {
      position: relative;
      button.image-button.reassign {
        position: absolute;
        top: 0;
        bottom: 30px;
        margin: auto;
        width: 48px;
        height: 30px;
        background-image: url('/asserts/icon/missions-load-mission-icon-normal.svg');
        background-repeat: no-repeat;
      }
    }
  }
}

</style>

<style lang="scss" scoped>
.sub-app {
  display: flex;
  flex-direction: column;
  .app-main-toolbar {
    margin-bottom: 280px !important;
  }

  .column-data-table {
    width: 80%;
    margin: 0 auto;
  }

  .trigger-data-table {
    margin-top: 120px;
  }

  .title-section {
    margin-top: 20px;
  }
}

</style>
