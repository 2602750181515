<!--
  請注意 ColumnDataTable 沒有實作所有 DataTable 的功能
  這邊只實作 Event Triggers 所需要的最低限度功能
 -->

<template>
  <div class="column-data-table">
    <div class="column-data-table-toolbar">
      <slot name="toolbar" />
    </div>
    <div
      v-if="data.length > 0"
      class="column-data-table-body"
    >
      <div
        v-for="(row, rIndex) in data"
        :key="'row' + rIndex"
        class="column-data-table-item"
      >
        <div
          v-for="(col, cIndex) in config.tableColumnProps"
          :key="'row-' + rIndex + 'col-' + cIndex"
          :class="{'column-data-table-item__cell': true, [col.className]: col.className}"
          :style="{ width: col.width, minWidth: col.minWidth }"
        >
          {{ format(row[col.prop]) }}
        </div>

        <div class="column-data-table-item__cell action">
          <div class="action-group">
            <slot
              name="actions"
              :row="row"
              :rIndex="rIndex"
            />
            <el-switch
              v-if="config.toggleField != null"
              v-model="toggleList[rIndex]"
              :disabled="!isAdmin"
              class="action-button coretronic"
              @change="$emit('toggle', row.id, rIndex)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FaButton from '@/components/FaButton';
import { mapActions, mapGetters } from 'vuex';
import { logger } from '@/logger/index';

export default {
  name: 'ColumnTable',
  components: {
    FaButton,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      required: true,
    },
    formatter: {
      required: false,
      type: Function,
    },
  },
  computed: {
    toggleList() {
      return this.data.map((row) => row[this.config.toggleField]);
    },
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
  },
  methods: {
    format(value) {
      logger.debug(this.formatter);
      if (this.formatter) {
        return this.formatter(value);
      }
      return value;
    },
  },
};
</script>

<style lang="scss">
.column-data-table {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-width: 1350px;

  .column-data-table-toolbar {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .column-data-table-body {
    background-color: rgba(216, 216, 216, 0.3);
    padding: 50px 81px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .column-data-table-item {
      background-color: white;
      width: 100%;
      height: 78px;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      display: table;
      padding: 0 5%;

      .column-data-table-item__cell {
        display: table-cell;
        vertical-align: middle;
        padding: 0px 5px;
      }

      .column-data-table-item__cell.monitor {
        color : #0c5c9c;
      }

      .column-data-table-item__cell.type {
        color: #0c5c9c;
      }

      .column-data-table-item__cell.action {
        width: 90px;
        text-align: right;
        .action-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        .action-button:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    .column-data-table-item:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
</style>
