<template>
  <div class="sub-app location">
    <section class="app-main-toolbar">
      <el-row class="no-margin">
        <h1>{{ $t('page.event.title.eventList') }}</h1>
      </el-row>
      <el-row
        type="flex"
        justify="end"
      />
    </section>
    <section class="app-main-content">
      <data-table
        :data="source.items"
        :current-page="filter.page"
        :page-size="20"
        :total="source.paging.total"
        :loading="page.loading"
        :config="page.tableProps"
        :show-tool-bar="false"
        layout="table, pagination"
        :table-props="{ border: false,
                        height: &quot;calc(100% - 74px)&quot;,
                        &quot;row-class-name&quot;: &quot;coretronic&quot;,
                        &quot;header-row-class-name&quot;: &quot;coretronic table-header&quot; }"
        :pagination-props="{ background: true,
                             small: true,
                             layout: 'prev, pager, next',
                             pageSizes: [20],
                             disabled: page.loading}"
        @query-change="loadData"
      >
        <el-table-column
          v-for="columnProp in page.tableProps.tableColumnProps"
          :key="columnProp.prop"
          :type="columnProp.type"
          :label="$t(columnProp.label)"
          :column-key="columnProp.columnKey"
          :prop="columnProp.prop"
          :width="columnProp.width"
          :min-width="columnProp.minWidth"
          :sortable="columnProp.sortable"
          :resizable="columnProp.resizable"
          :formatter="columnProp.formatter"
          :align="columnProp.align"
          :header-align="columnProp.headerAlign"
          :class-name="columnProp.className"
          :label-class-name="columnProp.labelClassName"
        >
          <template scope="scope">
            <div v-if="columnProp.prop == 'description'">
              {{ scope.row.error || scope.row.result }}
            </div>
            <div v-else-if="columnProp.prop == 'type'">
              {{ $t('page.event.typeType.' + scope.row.type) }}
            </div>
            <div v-else-if="columnProp.prop == 'create_time'">
              {{ scope.row.create_time | formatDateTime($t('formatter.dateTime')) }}
            </div>
            <div v-else>
              {{ scope.row[columnProp.prop] }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label=""
          fixed="right"
          width="55px"
        />
      </data-table>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import tableProps from '@/config/tableProps/eventList.js';
import DataTable from '@/components/DataTable/DataTable';
import FaButton from '@/components/FaButton';
import EventApi from '@/services/api/domain/group/triggerEvent';

export default {
  name: 'EventSubApp',
  components: {
    DataTable,
    FaButton,
  },
  data() {
    return {
      deleteShow: false,
      source: {
        items: [],
        paging: {
          total: 0,
        },
      },
      filter: {
        page: 1,
      },
      page: {
        loading: false,
        selectedItems: [],
        tableProps,
      },
      loadDataNonce: null,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      groupId: 'user/groupId',
    }),
  },

  async mounted() {
  },
  methods: {
    async loadData(filter) {
      const localNonce = this.loadDataNonce = new Object();
      if (filter && filter.page && filter.pageSize) {
        this.filter.page = filter.page;
        this.filter.limit = filter.pageSize;
      }

      if (filter.sort != null && filter.sort.prop != null) {
        this.filter.sort = `${filter.sort.prop}${(filter.sort.order == 'descending') ? ':des' : ''}`;
      } else {
        this.filter.sort = null;
      }

      try {
        this.page.loading = true;
        const { meta, data } = await EventApi.getAll(this.groupId, this.filter);
        if (localNonce !== this.loadDataNonce) {
          // a new call was made
          return;
        }
        this.source.items = data.events;
        this.source.paging.total = data.total;
        this.page.loading = false;
      } catch (error) {
        this.$showFail(error);
        this.page.loading = false;
      /* finally 會再 return 前執行，這會導致 loading 提示消失 */
      // } finally {
      //   // this.page.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
// .sub-app.location{
// }

.el-slider.setting-altitude-slider {
  margin-left: 10px;
  margin-right: 10px;
  .el-slider__runway {
    background-color: #d8d8d8;
  }

  .el-slider__bar {
    background-color: #ffc900;
  }
  .el-slider__button {
    width: 22px;
    height: 22px;
    border: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

.setting-slide-and-box {
  input.el-input__inner {
    text-align: center;
  }
}
</style>
