<template>
  <admin-fillall-layout>
    <trigger></trigger>
  </admin-fillall-layout>
</template>

<script>
import TabContent from '@/components/TabContent.vue';
import Event from './Event.vue';
import Trigger from './Trigger.vue';

export default {
  name: 'EventHome',
  components: {
    TabContent,
    // Event,
    Trigger,
  },
  data() {
    return {
      tabList: [
        // {
        //   label: 'page.event.tab.eventList',
        //   name: 'event',
        //   component: Event,
        // },
        {
          label: 'page.event.tab.triggerForward',
          name: 'trigger',
          component: Trigger,
        },
      ],
    };
  },
};
</script>
