import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1/groups`;

export default {
  getAll(groupId, filter) {
    return axios
      .get(`${apiUrl}/${groupId}/triggers`, { params: filter })
      .then((response) => {
        return response.data;
      });
  },
  get(groupId, id) {
    return axios
      .get(`${apiUrl}/${groupId}/triggers/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create(groupId, trigger) {
    return axios
      .post(`${apiUrl}/${groupId}/triggers`, trigger)
      .then((response) => {
        return response.data;
      });
  },
  update(groupId, triggerId, trigger) {
    return axios
      .put(`${apiUrl}/${groupId}/triggers/${triggerId}`, trigger)
      .then((response) => {
        return response.data;
      });
  },
  delete(groupId, id) {
    return axios
      .delete(`${apiUrl}/${groupId}/triggers/${id}`)
      .then((response) => {
        return response.data;
      });
  },
};
